import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"

export default function NewPlayersWantedU9() {
  return ( 
    <Layout>
        <section className="container">
        <h1>New Players Wanted U10s Girls</h1>
        <InnerPageTwitterLayout>
            <img src="new-players-wanted-u9s-girls.jpg" className="img-fluid mb-4 team-page-hero" />
            <p className="lead">Our current under 10’s girls (YR 5) are looking for new PLAYERS.</p>
            <p>We play in the West Riding Girls league on a Saturday mornings and train every Wednesday 5:30 till 6:30. Please contact us at <a href="mailto:leedscityjnrs@gmail.com">leedscityjnrs@gmail.com</a>.</p>
            <p>For more information about the team see the team specific page <Link to="/team-girls-u10">U10s Girls</Link>.</p>
        </InnerPageTwitterLayout>
      </section>
    </Layout>
  );
}
